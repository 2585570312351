<template>
  <button
    class="botao-acao"
    :class="tema ? tema : 'padrao'"
    @click.left.prevent="$emit('handleClick')"
  >
    <i class="material-icons-sharp">
      <slot name="icon"></slot>
    </i>

    <span>
      <slot></slot>
    </span>

    <span class="atalho">
      <slot name="atalho"></slot>
    </span>
  </button>
</template>

<script>
export default {
  props: {
    tema: String
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/vars';

.botao-acao {
  box-sizing: border-box;
  width: 100%;
  height: 45px;
  border-radius: 4px;
  color: $branco;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  & i {
    font-size: 24px;
  }

  & .atalho {
    font-size: 0.8rem;
    opacity: 0.7;
  }
}

// @media screen and (min-width: 540px) {
//     .botao-acao {
//         min-width: 49.25%;
//     }
// }

.padrao {
  border: 2px solid $botaoContorno;
  background-color: $botao;

  &:hover {
    background-color: $botaoHover;
  }

  &:active {
    background-color: $botao;
  }
}

.vermelho {
  border: 2px solid #f80e0e;
  background-color: #e22b2b;

  &:hover {
    background-color: #eb5d5d;
  }

  &:active {
    background-color: #e22b2b;
  }
}

.verde {
  border: 2px solid #3a8638;
  background-color: #276e24;

  &:hover {
    background-color: #3f8d56;
  }

  &:active {
    background-color: #276e24;
  }
}

.branco {
  border: none;
  box-shadow: 0px 0px 2px 1px #ababab8e;
  background-color: #f0f0f0;
  color: #000;

  &:hover {
    background-color: #ababab31;
  }

  &:active {
    background-color: #f0f0f0;
  }
}

.laranja {
  border: none;
  background-color: #e85d37;
  // border-radius: 50px;

  &:hover,
  &:active {
    background-color: #e85d37c4;
  }

  &:active {
    background-color: #e85d37;
  }
}
</style>
