import { lazyLoad, mudarModulo, verificarAcessoModulo } from '@/lib/utils/router'
import app from '@/main'
import store from '@/store'
import sessao from '@/lib/utils/sessao'

export default [
  {
    path: '/desktop-vendas',
    name: 'desktopVendas',
    component: lazyLoad('desktopVendas/DesktopVendasHome'),
    redirect: {
      name: 'desktopVendasHome'
    },
    children: [
      {
        path: 'pedido',
        name: 'desktopVendasHome',
        component: lazyLoad('desktopVendas/PedidoCriacao')
      },
      {
        path: 'pedido/preview',
        name: 'pedidoPreview',
        component: lazyLoad('desktopVendas/PedidoPreview')
      },
      {
        path: 'fechamento',
        name: 'fechamentoPedidos',
        component: lazyLoad('desktopVendas/FechamentoPedidos'),
        children: [
          {
            path: 'pedido/:ordem',
            name: 'fechamentoPedidoOrdem',
            component: lazyLoad('desktopVendas/FechamentoOrdem'),
            redirect: {
              name: 'fechamentoPedidoOrdemInformacoes'
            },
            children: [
              {
                path: '',
                name: 'fechamentoPedidoOrdemInformacoes',
                component: lazyLoad('desktopVendas/FechamentoOrdemInformacoes')
              },
              {
                path: 'editar-pagamento',
                name: 'fechamentoPedidoOrdemEditarPagamento',
                component: lazyLoad('desktopVendas/FechamentoOrdemEditarPagamento')
              }
            ]
          }
        ]
      }
    ],
    beforeEnter: async (to) => {
      try {
        const { data } = await app.axiosInstance.get('/modulos/retornarUsuario')
        if (data) {
          store.dispatch('empresa/setEmpresa', data.USUARIO)
          store.dispatch('orcamento/salvarVendedor', data.VENDEDOR)
          store.dispatch('orcamento/salvarPDF', data.DADOSPDF)

          store.dispatch('orcamento/salvarPermissoes', data.PERMISSOES)
          store.dispatch('orcamento/salvarSaldoNegativo', data.SALDONEGATIVO)
          store.dispatch('orcamento/salvarLocalizacao', data.LOCALIZACAO)
          store.dispatch('orcamento/salvarCentroCustoAtivo', data.CENTRODECUSTO)
          store.dispatch('orcamento/salvarUsaTabelaPreco', data.USATABELAPRECO)

          const tabela = sessao.getItemSessao('TABELAPRECO')
          if (!tabela) {
            store.dispatch('orcamento/salvarTabelaPreco', data.TABELAPRECO)
          }

          ![null, undefined, ''].includes(data.IMAGENS)
            ? store.dispatch('orcamento/salvarImagem', 'T')
            : store.dispatch('orcamento/salvarImagem', 'F')

          const clienteRota = sessao.getItemStorage('CLIENTE')
          if (!clienteRota && !clienteRota.CODIGOROTA) {
            store.dispatch('orcamento/salvarCliente', data.PARCEIRO)
          }
        }
        const configuracoesIndividuais = await app.axiosInstance.get(
          '/modulos/retornarPermissoesUsuario'
        )
        const configuracoesGerais = await app.axiosInstance.get(
          '/modulos/retornarConfiguracoesGerais',
          { params: { CNPJ: store.state.empresa.CNPJ } }
        )
        const permissoes = {}
        Object.assign(permissoes, configuracoesGerais.data, configuracoesIndividuais.data)
        store.dispatch('empresa/setPermissoes', permissoes)

        await verificarAcessoModulo('desktop-vendas')
        await mudarModulo(to)
      } catch (error) {
        console.log(error)
        error = error.response.data.erro
        return tratarFaltaDeAcesso(to, error)
      }
    }
  }
]
