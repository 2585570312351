import app from '../main'
import sessao from '../lib/utils/sessao'

const store = {
  namespaced: true,
  state: {
    opcoes: []
  },
  mutations: {
    preencherOpcoes(state, opcoes) {
      state.opcoes = opcoes
    },
    adicionarFiltro(state, { index, codigoRelatorio, filtro }) {
      if (!state.todosFiltros[index]) state.todosFiltros.push({})
      const filtroAtual = state.todosFiltros[index]

      filtroAtual.CODIGORELATORIO = codigoRelatorio
      if (filtro.length) filtroAtual.FILTROS = filtro
    }
  },
  actions: {
    async listarRelatorios({ commit }) {
      const { data } = await app.axiosInstance.get('/relatorios/listarRelatorios')
      commit('preencherOpcoes', data)
    },
    async preencherListaDeFiltros({ state, commit }) {
      for (let i = 0; i < state.opcoes.length; i++) {
        const opcao = state.opcoes[i]

        try {
          const { data } = await app.axiosInstance.get('/relatorios/retornarFiltros', {
            params: {
              CODIGORELATORIO: opcao.CODIGORELATORIO
            }
          })
          opcao.FILTROS = data
        } catch (error) {
          console.log(error)
        }
      }
    }
  }
}

export default {
  store
}
