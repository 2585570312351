import sessao from './sessao.js'

function verificarSeAPlataformaEhMobile() {
  const reAndroid = /Android/g
  const reIphone = /iPhone/g
  const reIpad = /iPad/g
  const plataforma = sessao.getPlataforma()

  return reAndroid.test(plataforma) || reIphone.test(plataforma) || reIpad.test(plataforma)
}

function prevenirQueValoresDeUmObjetoSejamUndefinedDadoUmaLista(obj, lista) {
  for (let campos of lista) {
    if (obj[campos] === undefined) {
      obj[campos] = ''
    }
  }
  return obj
}

function retornarConsultaPaginada(materiais) {
  const quantidadeItensPorPagina = 5
  const quantidadePaginas = Math.ceil(materiais.length / quantidadeItensPorPagina)

  const paginas = new Object()
  for (let pag = 1; pag <= quantidadePaginas; pag++) {
    const inicio = (pag - 1) * quantidadeItensPorPagina
    const fim = inicio + quantidadeItensPorPagina

    const itens = []
    for (let i = inicio; i < fim; i++) {
      const item = materiais[i]
      if (item) {
        itens.push(materiais[i])
      }
    }
    paginas[pag] = itens
  }
  return paginas
}

function getValorCookie(chave) {
  const cookie = document.cookie
  const cookieModulo = cookie.indexOf(chave)

  if (cookie.length && cookieModulo !== -1) {
    const finalChave = cookieModulo + chave.length
    const existeMaisChaves = cookie.indexOf(';', finalChave) !== -1

    if (existeMaisChaves) {
      return cookie.slice(finalChave + 1, cookie.indexOf(';', finalChave))
    } else {
      return cookie.slice(finalChave + 1)
    }
  } else {
    return false
  }
}

export default {
  verificarSeAPlataformaEhMobile,
  prevenirQueValoresDeUmObjetoSejamUndefinedDadoUmaLista,
  retornarConsultaPaginada,
  getValorCookie
}
