import Vendas from '@/views/clienteVendas/Vendas.vue'

import app from '@/main'
import store from '@/store'
import { lazyLoad } from '../lib/utils/router'

const autenticarUsuarioVendas = async () => {
  try {
    const { data } = await app.axiosInstance.get('/clienteVendas/retornarUsuario')
    store.dispatch('orcamento/salvarSaldoNegativo', data.EMPRESA_PROVEDORA.SALDONEGATIVO)
    store.dispatch('orcamento/salvarModoIndustria', data.EMPRESA_PROVEDORA.MODOINDUSTRIA)
    store.dispatch('orcamento/salvarFormaIndustria', data.EMPRESA_PROVEDORA.FORMAINDUSTRIA)
    store.dispatch('orcamento/salvarTipoIndustria', data.EMPRESA_PROVEDORA.TIPOINDUSTRIA)
  } catch (error) {
    return {
      name: 'avisoNaoAutenticado'
    }
  }
}

export default [
  {
    path: '/cliente-vendas',
    name: 'homeVendas',
    component: Vendas,
    redirect: {
      name: 'categoriaVendas'
    },
    children: [
      {
        path: 'produtos/:categoria*',
        name: 'categoriaVendas',
        component: lazyLoad('clienteVendas/Categorias')
      },
      {
        path: 'produtos/pesquisa/:pesquisa',
        name: 'pesquisaProdutos',
        component: lazyLoad('clienteVendas/Pesquisa')
      }
    ],
    beforeEnter: autenticarUsuarioVendas
  },
  {
    path: '/cliente-vendas/pagamento',
    name: 'pagamentoVendas',
    component: lazyLoad('clienteVendas/Pagamento'),
    beforeEnter: autenticarUsuarioVendas
  },
  {
    path: '/cliente-vendas/login',
    redirect: {
      name: 'avisoAutenticado'
    }
  },
  {
    path: '/cliente-vendas/login/:cnpj',
    redirect: {
      name: 'avisoAutenticado'
    }
  },
  {
    path: '/cliente-vendas/login/:cnpj/:codigo',
    name: 'vendasLogin',
    component: lazyLoad('clienteVendas/LoginVendas')
  },
  {
    path: '/nao-autenticado',
    name: 'avisoNaoAutenticado',
    component: lazyLoad('clienteVendas/AvisoAutenticacao')
  }
]
