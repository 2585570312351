<template>
  <div class="modal-background" tabindex="0" @keyup.esc="$emit('fecharModal')" ref="modal">
    <div class="modal-scroll">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$refs.modal.focus()
    document.body.style.overflowY = 'hidden'
    document.childNodes[1].style.overflowY = 'hidden'
  },
  unmounted() {
    document.body.style.overflowY = 'auto'
    document.childNodes[1].style.overflowY = 'auto'
  }
}
</script>

<style lang="scss" scoped>
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  background-color: rgb(0, 0, 0); /* cor de fundo para rodear o modal */
  background-color: rgba(0, 0, 0, 0.6); /* o mesmo de cima só que com opacidade */
  width: 100%;
  height: 100%;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-scroll {
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  border-radius: 4px;
  overflow-y: auto;

  &,
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > div {
    width: fit-content;
    height: fit-content;
  }
}

@media screen and (max-width: 780px) {
  .modal-background {
    width: 100vw;
  }

  .modal-scroll {
    & > div {
      width: 100%;
    }
  }
}
</style>
