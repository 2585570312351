<template>
  <ModalTemplate>
    <template #title>Visualizar Imagem</template>

    <template #body>
      <label class="labelImg" for="imgModal">{{ itemSelecionado.DESCRICAO }}</label>
      <img class="imgModal" :src="link" alt="Imagem" />
    </template>
  </ModalTemplate>
</template>

<script>
import ModalTemplate from '../../../../components/modais/templates/ModalTemplate.vue'

export default {
  components: {
    ModalTemplate
  },
  props: {
    itemSelecionado: Object
  },
  data() {
    return {
      link: ''
    }
  },
  methods: {
    async checkImageLinks(links) {
      for (let link of links) {
        if (await this.checkImageLink(link)) {
          this.link = link
          return
        }
      }
      this.link = 'https://vendas.og1.inf.br/imagensweb/noImage.jpg'
    },
    checkImageLink(link) {
      return new Promise((resolve) => {
        let img = new Image()
        img.onload = () => {
          resolve(true)
        }
        img.onerror = () => {
          resolve(false)
        }
        img.src = link
      })
    }
  },
  async mounted() {
    await this.checkImageLinks([this.itemSelecionado.IMAGEM, this.itemSelecionado.IMAGEMAUXILIAR])
  }
}
</script>

<style lang="scss">
.imgModal {
  max-width: 340px;
  max-height: 442px;
}

.labelImg {
  margin-bottom: 20px;
  font-weight: bold;
}
</style>
