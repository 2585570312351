<template>
  <div style="position: relative; padding-bottom: 50px">
    <HeaderVendas :barra-pesquisa="true" />

    <div v-if="$store.state.clienteVendas.headerFixa" class="placeholder"></div>

    <main class="vendas-main">
      <RouterView />
    </main>

    <Transition name="effect">
      <Carrinho v-if="this.$store.state.clienteVendas.carrinhoAberto" />
    </Transition>

    <FooterOG1 />
  </div>
</template>

<script>
import HeaderVendas from '@/components/clienteVendas/HeaderVendas.vue'
import Carrinho from './Carrinho.vue'
import FooterOG1 from '@/components/FooterOG1.vue'

export default {
  components: {
    HeaderVendas,
    Carrinho,
    FooterOG1
  },
  mounted() {
    this.$store.dispatch('clienteVendas/restaurarCarrinho')
  }
}
</script>

<style lang="scss" scoped>
.vendas-main {
  max-width: 1024px;
  margin: 0.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.placeholder {
  width: 100%;
  height: 80px;
}

@media screen and (min-width: 768px) {
  .vendas-main {
    margin: 2rem;
  }
}

@media screen and (min-width: 1200px) {
  .vendas-main {
    margin: 1rem auto;
  }
}
</style>
