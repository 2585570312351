<template>
  <section class="item-pedido">
    <div class="item-pedido__info">
      <img :src="$store.state.clienteVendas.carrinho[codigo][0].IMAGEM" />

      <div class="descricao">
        <button>{{ $store.state.clienteVendas.carrinho[codigo][0].DESCRICAO }}</button>
      </div>
    </div>

    <div class="item-pedido__quantidade">
      <span>{{ $store.getters['clienteVendas/getQuantidade'](codigo) }}</span>
    </div>

    <div
      v-if="!promocao && ['F', null, undefined].includes(modoIndustria)"
      class="item-pedido__preco"
    >
      <span>{{ preco }} </span>
    </div>

    <div
      v-if="promocao && ['F', null, undefined].includes(modoIndustria)"
      class="item-pedido__preco item-pedido__promocao"
    >
      <span>{{ preco }}</span>
      <span>{{ promocao }}</span>
    </div>
  </section>
</template>

<script>
import fmt from '@/lib/utils/formatacao'
import sessao from '@/lib/utils/sessao'

export default {
  props: ['codigo'],
  computed: {
    preco() {
      let preco = this.$store.getters['clienteVendas/getPreco'](this.codigo)
      return `R$ ${fmt.retornarNumberEmMonetario(preco)}`
    },
    promocao() {
      let promocao = this.$store.getters['clienteVendas/getPromocao'](this.codigo)
      if (!promocao) return promocao
      return `R$ ${fmt.retornarNumberEmMonetario(promocao)}`
    },
    modoIndustria() {
      return sessao.getItemSessao('MODOINDUSTRIA')
    }
  }
}
</script>

<style lang="scss" scoped>
.item-pedido {
  box-sizing: border-box;

  width: 100%;
  padding: 5px 10px;
  margin: 0.5rem 0;
  border-radius: 2px;
  box-shadow: 0 2px 4px #00000014;

  background-color: #f7f5f5;

  display: flex;
  align-items: center;
  justify-content: space-between;

  & img {
    mix-blend-mode: multiply;

    width: 40px;
    height: 40px;
    margin: 5px;
  }

  &__info {
    display: flex;

    & .descricao {
      display: flex;

      & button {
        width: 180px;
        border: none;

        background-color: transparent;
        text-align: left;
      }
    }
  }

  &__quantidade {
    width: 50px;
    padding: 5px 10px;
    border-radius: 2px;

    background: #e9ecef;
    text-align: center;
    font-weight: 700;
  }

  &__preco {
    text-align: right;
  }

  &__promocao {
    display: flex;
    flex-direction: column;

    & span {
      &:first-child {
        text-decoration: line-through;
        font-size: 0.8rem;
      }
    }
  }
}
</style>
