const store = {
  namespaced: true,
  state: {
    CNPJ: '',
    NOMEFANTASIA: '',
    CODIGOALMOXARIFADO: '',
    CODIGOUSUARIO: '',
    CODIGOGRUPO: '',
    CODIGOFUNCIONARIO: '',
    ADMIN: false,
    TABELAPRECOATIVA: false,
    PERMISSOES: []
  },
  mutations: {
    setCNPJ(state, cnpj) {
      state.CNPJ = cnpj
    },
    setNomeFantasia(state, nomeFantasia) {
      state.NOMEFANTASIA = nomeFantasia
    },
    setCodigoAlmoxarifado(state, codigoAlmoxarifado) {
      state.CODIGOALMOXARIFADO = codigoAlmoxarifado
    },
    setCodigoUsuario(state, codigo) {
      state.CODIGOUSUARIO = codigo
    },
    setCodigoGrupo(state, codigo) {
      state.CODIGOGRUPO = codigo
    },
    setCodigoFuncionario(state, codigo) {
      state.CODIGOFUNCIONARIO = codigo
    },
    setAdmin(state, admin) {
      state.ADMIN = admin
    },
    setPermissoes(state, permissoes) {
      state.PERMISSOES = permissoes
    },
    setTabelaPrecoAtiva(state, ativa) {
      state.TABELAPRECOATIVA = ativa
    }
  },
  actions: {
    setEmpresa({ commit }, empresa) {
      commit('setCNPJ', empresa.CNPJ)
      commit('setNomeFantasia', empresa.NOMEFANTASIA)
      commit('setCodigoAlmoxarifado', empresa.CODIGOALMOXARIFADO)
      commit('setCodigoUsuario', empresa.CODIGOUSUARIO)
      commit('setCodigoGrupo', empresa.CODIGOGRUPO)
      commit('setCodigoFuncionario', empresa.CODIGOFUNCIONARIO)
      commit('setAdmin', empresa.ADMIN)
      commit('setTabelaPrecoAtiva', empresa.TABELAPRECOATIVA)
    },
    setPermissoes({ commit }, permissoes) {
      commit('setPermissoes', permissoes)
    }
  }
}

export default { store }
