<template>
  <BaseModalBackdrop>
    <BaseModalConteiner class="modal-informativo-conteiner">
      <header class="modal-header">
        <h2>
          <slot name="header"> Title </slot>
        </h2>
      </header>

      <section class="modal-body">
        <i class="material-icons-sharp">
          <slot name="icon"></slot>
        </i>
        <p>
          <slot name="message"></slot>
        </p>
      </section>

      <footer class="modal-footer">
        <slot name="footer">
          <BaseBotaoAcao @handleClick="$emit('fecharModal')"> Fechar </BaseBotaoAcao>
        </slot>
      </footer>
    </BaseModalConteiner>
  </BaseModalBackdrop>
</template>

<script>
import BaseModalBackdrop from './BaseModalBackdrop.vue'
import BaseModalConteiner from './BaseModalConteiner.vue'
import BaseBotaoAcao from '../../BaseBotaoAcao.vue'

export default {
  components: {
    BaseModalBackdrop,
    BaseModalConteiner,
    BaseBotaoAcao
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/vars';
$border-radius: 4px;

.modal-informativo-conteiner {
  max-width: 400px;
}

@media screen and (max-width: 450px) {
  .modal-informativo-conteiner {
    width: 95vw;
  }
}

.modal-header {
  padding: 15px;
  background-color: $preto;
  color: $brancoPuro;

  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-body {
  padding: 0.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  & i {
    font-size: 5rem;
  }

  & p {
    font-size: 0.9rem;
    margin: 5px 0;
  }
}

.modal-footer {
  padding: 0.5rem;
  background-color: $branco;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}

@media screen and (min-width: 500px) {
  .modal-footer {
    flex-direction: row;
  }
}
</style>
