<template>
  <div class="carregando-modal">
    <div class="carregando-modal-conteiner">
      <section class="carregando-modal__body">
        <div class="carregando-modal__body-esfera" style="--ordem: 0"></div>
        <div class="carregando-modal__body-esfera" style="--ordem: 1"></div>
        <div class="carregando-modal__body-esfera" style="--ordem: 2"></div>
        <div class="carregando-modal__body-esfera" style="--ordem: 3"></div>
      </section>
      <section class="carregando-modal__text">
        <h5>{{ $store.state.mensagemCarregando }}</h5>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  beforeMount() {
    document.body.style.overflowY = 'hidden'
  },
  beforeDestroy() {
    document.body.style.overflowY = 'auto'
  }
}
</script>
