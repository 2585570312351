<template>
  <div class="errorDiv">
    <h1 class="h1Error">Error 404</h1>
    <p class="p1Error">A página que você está procurando não foi encontrada.</p>
  </div>
</template>

<script>
export default {
  name: 'Error404'
}
</script>

<style>
.errorDiv {
  text-align: center;
  font-family: Arial, sans-serif;
}

.h1Error {
  font-size: 50px;
  color: #bbb;
  margin-top: 100px;
}

.pError {
  font-size: 20px;
  color: #333;
  margin-top: 20px;
}
</style>
