<template>
  <HeaderVendas />

  <div class="pagamento">
    <div class="pagamento__pedido">
      <div class="conteiner-categorias">
        <div v-if="['F', null, undefined].includes(modoIndustria)">
          <h2>Pagamento</h2>

          <div class="select">
            <label>Forma de Pagamento</label>

            <select id="forma-select" v-model="formaSelecionada">
              <option value="">Selecione...</option>
              <option v-for="forma in formas" :value="forma.CODIGOFORMAPAGAMENTO">
                {{ forma.DESCRICAO }}
              </option>
            </select>
          </div>

          <div class="select">
            <label>Tipo de Pagamento</label>

            <select id="modo-select" v-model="tipoSelecionado">
              <option value="">Selecione...</option>
              <option v-for="(tipo, index) in tipos" :value="tipo.CODIGOTIPOEVENTO">
                {{ tipo.DESCRICAO }}
              </option>
            </select>
          </div>
        </div>

        <div class="pagamento__pedido__itens">
          <div class="itens__titulo">
            <h2>Seu Pedido</h2>
            <button class="btn_checkout" @click="continuarComprando">
              <i class="material-icons-sharp" style="margin-right: 5px">shopping_cart</i>
              Alterar Compra
            </button>
          </div>

          <div class="pedido-items">
            <ItemPedido
              v-for="(v, codigo) in $store.state.clienteVendas.carrinho"
              :key="codigo"
              :codigo="codigo"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="pagamento__total">
      <div class="conteiner-categorias">
        <h2>Sua Compra</h2>
        <p v-if="modoIndustria === 'T'">Confira seus itens antes de finalizar sua compra</p>

        <div v-if="['F', null, undefined].includes(modoIndustria)" class="subtotal-conteiner">
          <ul>
            <li>
              <span>Total Bruto</span>
              <span>{{ totalBruto }}</span>
            </li>

            <li>
              <span>Desconto</span>
              <span>{{ descontoTotal }}</span>
            </li>

            <li class="li_total">
              <h6>TOTAL</h6>
              <span>{{ total }}</span>
            </li>
          </ul>
        </div>

        <div class="finalizar-compra" @click="inserirPedido()">
          <button class="finalizar-compra-btn">Finalizar Compra</button>
        </div>
      </div>
    </div>
  </div>

  <FooterOG1 />
</template>

<script>
import HeaderVendas from '@/components/clienteVendas/HeaderVendas.vue'
import ItemPedido from '@/components/clienteVendas/ItemPedido.vue'
import FooterOG1 from '@/components/FooterOG1.vue'

import sessao from '@/lib/utils/sessao'
import fmt from '@/lib/utils/formatacao'
import request from '@/lib/utils/request'

export default {
  components: {
    HeaderVendas,
    ItemPedido,
    FooterOG1
  },
  data() {
    return {
      formas: [],
      formaSelecionada: '',
      tipos: [],
      tipoSelecionado: '',
      formaIndustria: sessao.getItemSessao('FORMAINDUSTRIA'),
      tipoIndustria: sessao.getItemSessao('TIPOINDUSTRIA')
    }
  },
  computed: {
    subTotal() {
      let subTotal = this.$store.getters['clienteVendas/getSubTotal']
      return `R$ ${fmt.retornarNumberEmMonetario(subTotal)}`
    },
    total() {
      let total = this.$store.getters['clienteVendas/getSubTotal']
      return `R$ ${fmt.retornarNumberEmMonetario(total)}`
    },
    totalBruto() {
      let totalBruto = this.$store.getters['clienteVendas/getTotalBruto']

      return `R$ ${fmt.retornarNumberEmMonetario(totalBruto)}`
    },
    descontoTotal() {
      let totalBruto = this.$store.getters['clienteVendas/getTotalBruto']
      let total = this.$store.getters['clienteVendas/getSubTotal']
      let somaDesconto = totalBruto - total
      return `R$ ${fmt.retornarNumberEmMonetario(somaDesconto)}`
    },
    modoIndustria() {
      return sessao.getItemSessao('MODOINDUSTRIA')
    }
  },
  methods: {
    async getTiposPagamento() {
      if (this.formaSelecionada) {
        const { data } = await this.$axios.get('/clienteVendas/tipoPagamento', {
          params: {
            CODIGOFORMAPAGAMENTO: this.formaSelecionada
          }
        })
        this.tipos = data
      }
    },
    async inserirPedido() {
      try {
        const itens = []
        Object.keys(this.$store.state.clienteVendas.carrinho).forEach((codigo, i) => {
          const quantidade = this.$store.state.clienteVendas.carrinho[codigo].length

          if (this.$store.getters['clienteVendas/getPromocao'](codigo)) {
            var preco = this.$store.getters['clienteVendas/getPromocao'](codigo)
            var total = this.$store.getters['clienteVendas/getPromocao'](codigo) * quantidade
            var desconto = this.$store.state.clienteVendas.carrinho[codigo][0].DIFERENCA
          } else {
            var preco = this.$store.getters['clienteVendas/getPreco'](codigo)
            var total = this.$store.getters['clienteVendas/getPreco'](codigo) * quantidade
            var desconto = 0
          }

          itens.push({
            CODIGOMATERIAL: codigo,
            QUANTIDADE: quantidade,
            PRECO: preco,
            TOTALITEM: total,
            DESCONTO: desconto,
            ORDEMITEM: i + 1,
            CODIGOTIPOENTREGA: 0,
            UNIDADESAIDA: 'UN'
          })
        })

        await this.$axios.post(`/clienteVendas/inserirMovimento`, {
          VALORMATERIAL: this.$store.getters['clienteVendas/getSubTotal'],
          VALORTOTAL: this.$store.getters['clienteVendas/getSubTotal'],
          TOTALDESCONTO: 0,
          CODIGOFORMA: ['F', null, undefined].includes(this.modoIndustria)
            ? this.formaSelecionada
            : this.formaIndustria,
          CODIGOMODO: ['F', null, undefined].includes(this.modoIndustria)
            ? this.tipoSelecionado
            : this.tipoIndustria,
          STATUS: 'F',
          OBSERVACAO: '',
          LOCALIZACAO: {
            latitude: null,
            longitude: null
          },
          ITENS: itens
        })
        this.$store.dispatch('abrirModalPositivo', 'Compra finalizada com sucesso.')
        this.$store.dispatch('clienteVendas/esvaziarCarrinho')

        this.$router.push('/cliente-vendas/produtos')
      } catch (error) {
        this.$store.dispatch('abrirModalAlerta', request.tratarError(error))
      }
    },
    continuarComprando() {
      this.$router.push('/cliente-vendas/produtos')
    }
  },
  async mounted() {
    const carrinhoSessao = sessao.getItemSessao('CARRINHO')

    if (!carrinhoSessao || !Object.keys(carrinhoSessao).length) {
      this.$router.push({ name: 'categoriaVendas' })
    }
    this.$store.dispatch('clienteVendas/restaurarCarrinho')

    const formas = sessao.getItemSessao('FORMAPAGAMENTO')

    if (!formas) {
      const { data } = await this.$axios.get('/clienteVendas/formaPagamento')
      const filtrados = data.filter((el) => el.DESCRICAO)

      this.formas = filtrados
    } else {
      this.formas = formas
    }
  },
  watch: {
    formaSelecionada() {
      this.getTiposPagamento()
    }
  }
}
</script>

<style lang="scss" scoped>
.pagamento {
  margin: 2rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;

  &__pedido {
    width: 100%;

    &__itens {
      margin-top: 1.5rem;
    }
  }

  &__total {
    width: 100%;

    & h2 {
      border-bottom: 0;
    }
  }
}

.itens {
  &__titulo {
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;

    & h2 {
      border-bottom: none;
    }
  }
}

.subtotal-conteiner {
  padding: 16px;
  border-radius: 2px;
  box-shadow: 0 2px 4px #00000013;

  background-color: #f7f5f5;

  display: flex;
  flex-direction: column;

  & ul {
    list-style: none;

    margin: 0;
    padding: 0;
    width: 100%;

    & li {
      font-size: 0.9rem;
      margin-bottom: 0.5rem;
      line-height: 16px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      &:last-child {
        font-weight: 700;
        font-size: 1.2rem;
      }
    }
  }
}

.finalizar-compra {
  box-sizing: border-box;

  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;

  width: 100%;
  padding: 15px;
  border-top: 4px solid #f0f0f0;
  background-color: white;

  & :hover {
    background-color: #ef663c;
    cursor: pointer;
  }
}

@media screen and (min-width: 768px) {
  .pagamento {
    flex-direction: row;
  }

  .finalizar-compra {
    position: static;
    border: none;
    padding: 0;
  }
}

@media screen and (min-width: 1024px) {
  .pagamento {
    &__pedido {
      width: 60%;
    }

    &__total {
      width: 40%;
    }
  }
}
</style>
