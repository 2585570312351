<template>
  <section class="modal-body">
    <slot name="body"> Body </slot>
  </section>
</template>

<script>
import BaseModalBackdrop from './BaseModalBackdrop.vue'
import BaseModalConteiner from './BaseModalConteiner.vue'
import BaseBotaoAcao from '@/components/BaseBotaoAcao.vue'

export default {
  components: {
    BaseModalBackdrop,
    BaseModalConteiner,
    BaseBotaoAcao
  },
  props: {
    noFixedWidth: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/vars';
$border-radius: 4px;

.modal-body {
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  right: 15%;
  top: 15%;
  background-color: aqua;
  z-index: 20;
  min-width: 90px;
}

@media screen and (min-width: 540px) {
  .modal-header {
    flex-direction: row;
  }

  .fechar-modal {
    margin-left: 1rem;
  }
}
</style>
