<template>
  <ModalTemplateOrcamento
    :orcamento="orcamento"
    @imprimirMovimento="imprimirMovimento"
    @abrirDuplicarOrcamento="abrirDuplicarOrcamento"
    @fecharModal="$emit('fecharModal')"
    :no-fixed-width="true"
  >
    <template #title>ORÇAMENTO N°: {{ orcamento.ORDEM }}</template>

    <template #body>
      <div class="detalhes-orcamento__titulos">
        <span>{{ orcamento.DESCRICAO }}</span>
        <span>Código Cliente: {{ orcamento.CODIGOPARCEIRO }}</span>
        <span>Data: {{ orcamento.DATA }}</span>
        <span>Valor: R$ {{ orcamento.TOTAL }}</span>
        <span>Vendedor: {{ orcamento.VENDEDOR }}</span>
        <span>
          Status:
          <span style="font-weight: bolder">
            {{ orcamento.TIPO }}
          </span>
        </span>
        <span
          v-if="orcamento.CANCELADO === 'T'"
          class="movimento-cancelado"
          style="text-align: center; font-weight: bold"
        >
          Orçamento Cancelado!
        </span>
      </div>

      <div class="detalhes-orcamento__footer">
        <CarregandoInline />

        <div class="listar listar_itens">
          <BaseTabela
            v-if="itensResgatados.length"
            :itens="itens"
            :labels="[
              'Cód.',
              'Descrição',
              'Unid.',
              'Qtd.',
              'Unitário',
              'Desconto',
              'Total',
              'Entrega'
            ]"
            :attrs="[
              'CODIGOMATERIAL',
              'DESCRICAO',
              'UNIDADESAIDA',
              'QUANTIDADE',
              'UNITARIO',
              'DESCONTOPORCENTO',
              'TOTAL',
              'TIPOENTREGA'
            ]"
          />

          <div v-else>
            <span>{{ mensagemErro }}</span>
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <div
        class="opcoes-orcamento"
        v-if="
          !(
            orcamento.TIPO === 'Pedido' ||
            orcamento.TIPO === 'Pedido confirmado' ||
            orcamento.CANCELADO === 'T'
          )
        "
      >
        <BaseBotaoAcao @handleClick="confirmarComoVenda"> Confirmar Pedido </BaseBotaoAcao>

        <BaseBotaoAcao :tema="'vermelho'" @handleClick="cancelarMovimento">
          Cancelar
        </BaseBotaoAcao>
      </div>
    </template>
  </ModalTemplateOrcamento>

  <ModalConfirmarAcao
    v-if="duplicarOrcamentoAberto"
    @fecharModal="fecharDuplicarOrcamento"
    @confirmarAcao="resgatarItens(this.modo)"
  >
    <template #mensagem> Deseja {{ modo }} esse orçamento? </template>
  </ModalConfirmarAcao>

  <ModalAlerta v-if="itensCanceladosAberto" @fecharModal="fecharItensCancelados">
    <template #mensagem>
      Alguns itens serão cancelados ao prosseguir, pois não possuem estoque.
    </template>
  </ModalAlerta>

  <ModalAlerta v-if="erroImpressaoAberto" @fecharModal="fecharErroImpressao">
    <template #mensagem> Houve um erro ao tentar Imprimir este movimento. </template>
  </ModalAlerta>
</template>

<script>
import ModalTemplate from '@/components/modais/templates/ModalTemplate.vue'
import ModalTemplateOrcamento from '@/components/modais/templates/ModalTemplateOrcamento.vue'
import BaseModalBackdrop from '../../modais/templates/BaseModalBackdrop.vue'
import BaseModalConteiner from '@/components/modais/templates/BaseModalConteiner.vue'
import ModalConfirmarAcao from '../../modais/ModalConfirmarAcao.vue'
import CarregandoInline from '@/components/CarregandoInline.vue'
import ModalAlerta from '@/components/modais/ModalAlerta.vue'
import BaseBotaoAcao from '@/components/BaseBotaoAcao.vue'
import BaseTabela from '@/components/BaseTabela.vue'

import sessao from '@/lib/utils/sessao.js'
import req from '@/lib/utils/request'
import fmt from '@/lib/utils/formatacao'
import relatorio from '@/lib/utils/geradorRelatorio'

export default {
  emits: ['fecharModal', 'confirmarVenda', 'cancelarMovimento'],
  components: {
    ModalTemplate,
    ModalTemplateOrcamento,
    BaseModalBackdrop,
    BaseModalConteiner,
    ModalConfirmarAcao,
    CarregandoInline,
    ModalAlerta,
    BaseBotaoAcao,
    BaseTabela
  },
  props: ['orcamento'],
  data() {
    return {
      itensResgatados: [],
      novosItens: [],
      mensagemErro: '',
      cancelados: false,
      duplicarOrcamentoAberto: false,
      itensCanceladosAberto: false,
      erroImpressaoAberto: false,
      dadosCliente: {},
      modo: '',
      pagamentos: ''
    }
  },
  computed: {
    itens() {
      return this.itensResgatados.map((el) => {
        let entrega = ''

        switch (el.TIPOENTREGA) {
          case '0':
            entrega = 'Imediata'
            break
          case '1':
            entrega = 'Domicílio'
            break
          case '2':
            entrega = 'Loja'
            break
          case '3':
            entrega = 'Retira'
            break
          default:
            return el.TIPOENTREGA
        }

        return {
          CODIGOMATERIAL: el.CODIGOMATERIAL,
          DESCRICAO: el.DESCRICAO,
          UNIDADESAIDA: el.UNIDADESAIDA,
          QUANTIDADE: fmt.retornarNumberEmMonetario(el.QUANTIDADE),
          UNITARIO: 'R$ ' + fmt.retornarNumberEmMonetario(el.UNITARIO),
          DESCONTOPORCENTO: fmt.retornarNumberEmMonetario(el.DESCONTOPORCENTO) + '%',
          TOTAL: 'R$ ' + fmt.retornarNumberEmMonetario(el.TOTAL),
          TIPOENTREGA: entrega,
          CANCELADO: el.CANCELADO
        }
      })
    }
  },
  methods: {
    async povoarItens() {
      try {
        this.$store.dispatch('iniciarCarregamentoInline')
        const { data } = await this.$axios.get('/tirapedido/listarItensMovimento', {
          params: {
            ORDEM: this.orcamento.ORDEM
          }
        })
        if (data.mensagem) throw new Error(data.mensagem)

        const baseId = Date.now()
        this.itensResgatados = data.map((el, i) => {
          const quantidade = fmt.replaceCaracteresEmNumeros(el.QUANTIDADE)
          const precoVenda = fmt.replaceCaracteresEmNumeros(el.PRECO)
          const descontoReal = fmt.replaceCaracteresEmNumeros(el.DESCONTO)
          const total = fmt.replaceCaracteresEmNumeros(el.TOTALITEM)
          const unitario = precoVenda - descontoReal
          let descontoPorcento = 0

          if (precoVenda !== 0) {
            descontoPorcento = 100 - Math.round((unitario / precoVenda) * 100)
          }

          return {
            ID: baseId + i,
            CODIGOMATERIAL: el.CODIGOMATERIAL,
            DESCRICAO: el.DESCRICAO,
            UNIDADESAIDA: el.UNIDADESAIDA,
            QUANTIDADE: quantidade,
            UNITARIO: unitario,
            DESCONTOPORCENTO: descontoPorcento,
            DESCONTOREAL: descontoReal,
            PRECOVENDA: precoVenda,
            TOTAL: total,
            TIPOENTREGA: el.CODIGOTIPOENTREGA,
            CANCELADO: el.CANCELADO
          }
        })
      } catch (error) {
        this.mensagemErro = req.tratarError(error)
      } finally {
        this.$store.dispatch('pararCarregamentoInline')
      }
    },
    async resgatarItens(modo) {
      // modo recebe editar ou duplicar
      try {
        if (this.$store.state.orcamento.itens.length) {
          throw new Error(
            `Não é possível ${modo} este orçamento, pois já existe um orçamento em andamento!`
          )
        } else {
          this.$store.dispatch('iniciarCarregamento')

          let reducedItems = this.itensResgatados.reduce((acc, obj) => {
            let key = obj['CODIGOMATERIAL']
            if (!acc[key]) {
              acc[key] = { ...obj }
            } else {
              acc[key]['TOTAL'] += obj['TOTAL']
              acc[key]['QUANTIDADE'] += obj['QUANTIDADE']
            }
            return acc
          }, {})

          let reducedItemsArray = Object.values(reducedItems)

          const itensSessao = async () => {
            try {
              const cliente = await this.$axios.get('/tirapedido/listarClientes', {
                params: {
                  CAMPO: 'CODIGOPARCEIRO',
                  VALOR: this.orcamento.CODIGOPARCEIRO
                }
              })
              if (cliente.data.mensagem) throw new Error(cliente.data.mensagem)

              if (cliente.data.DESCRICAO) {
                cliente.data.DESCRICAOPARCEIRO = cliente.data.DESCRICAO
                delete cliente.data.DESCRICAO
              }
              this.dadosCliente = cliente.data[0]
              this.$store.dispatch('orcamento/salvarCliente', cliente.data[0])
            } catch (error) {
              this.$store.dispatch('abrirModalAlerta', req.tratarError(error))
            }
            const promises = reducedItemsArray.map(async (item) => {
              try {
                const { data } = await this.$axios.get('/tirapedido/listarMateriais', {
                  params: {
                    TIPOCODIGO: 'CODIGOMATERIAL',
                    CODIGO: item.CODIGOMATERIAL,
                    DESCRICAO: '',
                    CODIGOTABELAPRECO:
                      this.dadosCliente.TABELAPRECO.CODIGOTABELAPRECO ||
                      this.orcamento.CODIGOTABELAPRECO
                  }
                })

                if (data.mensagem) throw new Error(data.mensagem)

                //this.novosItens = data;
                const intPrecoDado = fmt.replaceCaracteresEmNumeros(data[0].PRECOVENDA)
                const intSaldoDado = fmt.replaceCaracteresEmNumeros(data[0].DISPONIVEL)
                item.UNITARIOFIXO = fmt.replaceCaracteresEmNumeros(data[0].PRECOVENDA)
                const venderNegativo = sessao.getItemSessao('SALDONEGATIVO')

                if (modo === 'duplicar') {
                  if (item.PRECOVENDA !== intPrecoDado || item.UNITARIO !== intPrecoDado) {
                    item.PRECOVENDA = intPrecoDado
                    item.UNITARIO = intPrecoDado
                    item.TOTAL = item.UNITARIO * item.QUANTIDADE
                  }
                  if (item.DESCONTOPORCENTO !== 0 || item.DESCONTOREAL !== 0) {
                    item.DESCONTOREAL = 0
                    item.DESCONTOPORCENTO = 0
                    item.TOTAL = item.UNITARIO * item.QUANTIDADE
                  }
                } else if (modo === 'editar') {
                  const { data } = await this.$axios.get('/tirapedido/resgatarPagamentoEdicao', {
                    params: {
                      ORDEM: this.orcamento.ORDEM
                    }
                  })
                  if (data.erro) throw new Error(data.erro)

                  this.pagamentos = data
                }
                if (venderNegativo === 'F' && item.QUANTIDADE > intSaldoDado) {
                  item.CANCELADO = 'T'
                }
                item.DISPONIVEL = intSaldoDado
                return item
              } catch (error) {
                this.$store.dispatch('abrirModalAlerta', req.tratarError(error))
              }
            })
            return Promise.all(promises)
          }
          this.novosItens = await itensSessao()
          this.$store.dispatch('pararCarregamento')

          this.novosItens.find((novoItem) => {
            if (novoItem.CANCELADO === 'T') {
              this.cancelados = true
            }
          })

          this.cancelados ? this.abrirItensCancelados() : this.redirecionarHome(this.modo)
        }
      } catch (error) {
        this.$store.dispatch('abrirModalAlerta', req.tratarError(error))
      } finally {
        this.fecharDuplicarOrcamento()
      }
    },
    abrirDuplicarOrcamento(modo) {
      this.modo = modo
      this.duplicarOrcamentoAberto = true
    },
    fecharDuplicarOrcamento() {
      this.duplicarOrcamentoAberto = false
    },
    abrirItensCancelados() {
      this.itensCanceladosAberto = true
    },
    fecharItensCancelados() {
      this.itensCanceladosAberto = false
      this.redirecionarHome(this.modo)
    },
    abrirErroImpressao() {
      this.erroImpressaoAberto = true
    },
    fecharErroImpressao() {
      this.erroImpressaoAberto = false
    },
    confirmarComoVenda() {
      this.$emit('confirmarVenda', this.orcamento.ORDEM)
    },
    cancelarMovimento() {
      this.$emit('cancelarMovimento', this.orcamento.ORDEM)
    },
    redirecionarHome(modo) {
      sessao.addEmStorage('ITENS', this.novosItens)
      if (modo === 'editar') {
        sessao.addEmStorage('NUMORDEM', this.orcamento.ORDEM)
        const objEdicao = { NUMORDEM: this.orcamento.ORDEM, ...this.pagamentos }
        sessao.addEmStorage('EDICAO', objEdicao)
      }

      this.$router.push({ name: 'homeTiraPedido' })
    },
    async imprimirMovimento() {
      this.$store.dispatch('iniciarCarregamento')
      try {
        const movimento = this.orcamento.ORDEM
          ? this.orcamento.ORDEM
          : this.orcamento.ordemOrcamento
        const { data } = await this.$axios.get('/relatorios/retornarPedido', {
          params: {
            VALOR: movimento
          }
        })
        if (data.mensagem || data.erro) {
          this.abrirErroImpressao()
          return
        }
        const pedido = await relatorio.imprimirConteudo(data.RELATORIO)
        this.$store.dispatch('pararCarregamento')

        if (pedido.erro) {
          this.$store.dispatch('abrirModalAlerta', 'Houve um erro ao exibir o PDF')
        }
      } catch (error) {
        this.$store.dispatch('abrirModalAlerta', req.tratarError(error))
      } finally {
        this.$store.dispatch('pararCarregamento')
      }
    }
  },
  beforeMount() {
    this.povoarItens()
  }
}
</script>

<style lang="scss" scoped>
.detalhes-orcamento {
  &__titulos {
    display: flex;
    flex-direction: column;

    & span {
      margin-bottom: 10px;
    }
  }

  &__footer {
    padding: 0 10px;
  }
}

.opcoes-orcamento {
  width: 70%;
  margin: 20px auto;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.duplicar,
.imprimir {
  cursor: pointer;
  box-sizing: border-box;

  width: 110px;
  height: 32px;
  padding: 4px 8px;
  border-radius: 6px;
  box-shadow: 0px 0px 2px 1px #ababab;
  font-weight: 500;
  color: #ffff;

  display: flex;
  align-items: center;
}

.duplicar {
  background-color: #2e2e2e;
}

.imprimir {
  background-color: #276e24;
}

.confirmar-venda,
.cancelar-orcamento {
  background-color: rgb(196, 48, 48);
  border-color: rgb(196, 48, 48);

  &:hover {
    background-color: rgb(230, 97, 97);
  }
}

.cancelar-orcamento {
  margin: 5px;
}

@media screen and (min-width: 540px) {
  .detalhes-orcamento {
    &__header {
      flex-wrap: nowrap;
    }
  }
}
</style>
