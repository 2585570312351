import app from '@/main'
import {
  lazyLoad,
  mudarModulo,
  tratarFaltaDeAcesso,
  verificarAcessoModulo
} from '@/lib/utils/router'
import store from '@/store'

export default [
  {
    path: '/dashboard',
    name: 'homeDashboard',
    component: lazyLoad('dashboard/DashboardHome'),
    redirect: { name: 'dashboard' },
    children: [
      {
        path: '',
        name: 'dashboard',
        component: lazyLoad('dashboard/TodosDashboards')
      },
      {
        path: ':id',
        name: 'specificDashboard',
        component: lazyLoad('dashboard/Dashboard')
      }
    ],
    beforeEnter: async (to) => {
      try {
        const { data } = await app.axiosInstance.get('/modulos/retornarUsuario')
        if (data) {
          store.dispatch('empresa/setEmpresa', data.USUARIO)
        }
        await verificarAcessoModulo('dashboard')
        await mudarModulo(to)
      } catch (error) {
        error = error.response.data.erro
        return tratarFaltaDeAcesso(to, error)
      }
    }
  }
]
