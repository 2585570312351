import app from '@/main'
import {
  lazyLoad,
  mudarModulo,
  tratarFaltaDeAcesso,
  verificarAcessoModulo
} from '@/lib/utils/router'
import store from '@/store'

export default [
  {
    path: '/gerar-link-cliente-vendas',
    name: 'gerarLinkClienteVendas',
    component: lazyLoad('gerarLinkClienteVendas/GerarLinkVendas'),
    beforeEnter: async (to) => {
      try {
        const { data } = await app.axiosInstance.get('/modulos/retornarUsuario')
        if (data) store.dispatch('empresa/setEmpresa', data.USUARIO)

        await verificarAcessoModulo('gerar-link-cliente-vendas')
        await mudarModulo(to)
      } catch (error) {
        error = error.response.data.erro
        return tratarFaltaDeAcesso(to, error)
      }
    }
  }
]
