import ModulosHome from '@/views/modulos/ModulosHome.vue'
import Modulos from '@/views/modulos/Modulos.vue'

import app from '@/main'
import store from '@/store'
import geral from '../lib/utils/geral'
import varsPermissoes from '@/lib/vars/permissoes'
import { lazyLoad, tratarFaltaDeAcesso } from '@/lib/utils/router'

export default [
  {
    path: '/modulos',
    name: 'modulosHome',
    component: ModulosHome,
    children: [
      {
        path: '',
        name: 'modulos',
        component: Modulos
      },
      {
        path: '/enviar-imagens',
        name: 'enviarImagens',
        component: lazyLoad('modulos/EnviarImagens')
      },
      {
        path: '/consultar-clientes',
        name: 'consultarClientesModulos',
        component: lazyLoad('tiraPedido/consultarClientes/ConsultarClientes')
      },
      {
        path: '/cadastrar-cliente',
        name: 'cadastrarClienteModulos',
        component: lazyLoad('tiraPedido/consultarClientes/CadastrarCliente')
      },
      {
        path: '/configuracoes-web',
        name: 'configuracoesWebHome',
        component: lazyLoad('modulos/ConfiguracoesHome'),
        children: [
          {
            path: '',
            name: 'configuracoesWeb',
            component: lazyLoad('modulos/Configuracoes'),
            beforeEnter: (to, from) => {
              const permissoes = store.state.empresa.PERMISSOES

              if (
                permissoes.admin &&
                permissoes.admin.find((c) => c.codigo === varsPermissoes.ADMIN_CONFIGURAR_USUARIOS)
              ) {
                return true
              } else {
                return { name: 'notFound' }
              }
            }
          },
          {
            path: ':modulo',
            name: 'configuracoesModulos',
            component: lazyLoad('modulos/ConfiguracoesModulo'),
            beforeEnter: (to) => {
              const configuracoes = store.state.configuracoesWeb.configuracoes
              if (to.params.modulo in configuracoes) {
                return true
              } else {
                return { name: 'configuracoesWeb' }
              }
            }
          },
          {
            path: 'visibilidade-dashboards',
            name: 'configuracoesVisibilidadeDashboards',
            component: lazyLoad('modulos/ConfiguracoesVisibilidadeDashboards'),
            beforeEnter: (to) => {
              if (store.state.dashboard.dashboards.length) {
                return true
              } else {
                return { name: 'configuracoesWeb' }
              }
            }
          }
        ]
      },
      {
        path: '/localizacao-web',
        name: 'localizacaoWeb',
        component: lazyLoad('modulos/Localizacao')
      }
    ],
    beforeEnter: async (to, from) => {
      try {
        sessionStorage.clear()
        const usuario = await app.axiosInstance.get('/modulos/retornarUsuario')
        await store.dispatch('empresa/setEmpresa', usuario.data.USUARIO)

        const configuracoes = await app.axiosInstance.get('/modulos/retornarPermissoesUsuario')
        await store.dispatch('empresa/setPermissoes', configuracoes.data)

        const modulo = geral.getValorCookie('modulo')
        if (!modulo) {
          return true
        } else {
          if (from.path.includes(modulo) || from.name === modulo) {
            return true
          } else {
            return modulo
          }
        }
      } catch (error) {
        return tratarFaltaDeAcesso(to, error.response.data.erro)
      }
    }
  }
]
