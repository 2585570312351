import store from '@/store'
import formatacao from './formatacao'
import sessao from './sessao'

import app from '@/main'

export default {
  renderizarGrafico({
    request,
    tipoGrafico,
    campoLegendas,
    campoDados,
    campoAgrupar,
    tituloGrafico,
    quantidadeDados = 0,
    cores = []
  }) {
    const computarLegendas = (req) => assegurarTamanhoArray(req.map((el) => el[campoLegendas]))

    const computarDados = (req) => {
      const converterDados = (el) => {
        let v = el[campoDados]
        try {
          if (typeof v === 'string') return formatacao.replaceCaracteresEmNumeros(v)
          else return v
        } catch (e) {
          console.log(
            'Não foi possível converter valor do gráfico. Retornando valor sem conversão.'
          )
          return v
        }
      }
      return assegurarTamanhoArray(req.map((el) => converterDados(el)))
    }

    const assegurarTamanhoArray = (arr) => {
      if (quantidadeDados && arr.length > quantidadeDados) return arr.slice(0, quantidadeDados)
      return arr.slice()
    }

    const datasets = []
    let legendas = []

    const chavesAgrupamento = campoAgrupar
      ? new Set(request.map((el) => el[campoAgrupar]))
      : new Set()
    if (chavesAgrupamento.size) {
      const dados = []
      let i = 0

      chavesAgrupamento.forEach((k) => {
        const filtrados = request.filter((el) => el[campoAgrupar] === k)
        if (!legendas.length) legendas = computarLegendas(filtrados)
        dados.push(computarDados(filtrados))

        datasets.push(
          this.computarDataset({
            tipoGrafico,
            label: k,
            dados: dados[i],
            totalDatasets: chavesAgrupamento.size,
            cores: cores[i]
          })
        )
        i++
      })
    } else {
      if (campoLegendas) {
        legendas = computarLegendas(request)
      } else {
        legendas = []
      }

      const dados = computarDados(request)
      datasets.push(
        this.computarDataset({
          tipoGrafico,
          label: tituloGrafico,
          dados,
          cores
        })
      )
    }

    return {
      labels: legendas,
      title: tituloGrafico,
      datasets
    }
  },
  computarDataset({ tipoGrafico, label, dados, cores = [] }) {
    const borderRadius = tipoGrafico === 'bar' ? 4 : 2

    if (cores && !cores.length) cores = this.gerarCoresParaGrafico(tipoGrafico, dados)

    return {
      label: label ? label : 'Placeholder',
      data: dados,
      backgroundColor: cores,
      borderRadius
    }
  },
  gerarCoresParaGrafico(tipoGrafico, dados) {
    const gerarCorHexadecimal = () => {
      return (
        '#' +
        Math.floor(Math.random() * 0x1000000)
          .toString(16)
          .padStart(6, '0') +
        'c6'
      )
    }

    if (['pie', 'doughnut', 'polar', 'radar'].includes(tipoGrafico))
      return dados.map(() => gerarCorHexadecimal())
    else return [gerarCorHexadecimal()]
  },
  computarPorcetagemTooltip(chart) {
    const grafico = chart[0]
    const soma = grafico.dataset.data.reduce((prev, acc) => prev + acc, 0)
    const valorHovered = grafico.raw
    let percentual = (valorHovered / soma) * 100
    if (!Number.isInteger(percentual)) {
      percentual = percentual.toFixed(2).replace('.', ',')
    }
    return percentual + '%'
  },
  validarVisibilidade(dashboard) {
    const usuarioEhAdmin = store.state.empresa.ADMIN
    const visibilidade = dashboard.visibilidadesdashboard

    if (visibilidade === null || (visibilidade && !visibilidade.tipo) || usuarioEhAdmin) {
      return true
    } else if (
      visibilidade.tipo === 'grupo' &&
      visibilidade.codigo === store.state.empresa.CODIGOGRUPO
    ) {
      return true
    } else if (
      visibilidade.tipo === 'usuario' &&
      visibilidade.codigo === store.state.empresa.CODIGOUSUARIO
    ) {
      return true
    } else {
      return false
    }
  },
  async postValidarSql(sql) {
    /**
     * propriedade na sessão só existirá
     * se algum funcionário da OG1 estiver editando a empresa.
     */
    const empresa = sessao.getItemSessao('CADASTROS')

    const body = { SQL: sql }
    if (empresa) body.EMPRESA = empresa

    const { data } = await app.axiosInstance.post('/dashboard/validarSql', body)
    return data
  },
  substituirMarcacoes(sqlInicial, parametros, fnSeParametroVazio) {
    const substituirMetaParametros = (parametro) => {
      const ocorrencias = this.procurarMetaParametro(sqlFinal)

      let sqlModificado = sqlFinal
      ocorrencias.forEach((metaParametro) => {
        if (metaParametro.includes(parametro)) {
          let operacao = ''
          let operacaoIndex = -1
          for (let op of ['+', '-']) {
            if (metaParametro.includes(op)) {
              operacao = op
              operacaoIndex = metaParametro.indexOf(op)
              break
            }
          }

          let operando = 0
          operando = parseInt(metaParametro.substring(operacaoIndex + 1))
          if (isNaN(operando)) {
            operando = parseInt(metaParametro.substring(1, operacaoIndex))
          }
          if (isNaN(operando)) return false

          const valor = parametros[parametro]
          let iv = parseInt(valor)
          iv = operacao === '+' ? iv + operando : iv - operando

          sqlModificado = sqlModificado.replaceAll(
            metaParametro,
            iv.toString().length === 1 ? `0${iv}` : `${iv}`
          )
        }
      })
      return sqlModificado
    }

    let sqlFinal = sqlInicial
    Object.keys(parametros).forEach((k) => {
      let v = parametros[k]
      if (v && typeof v === 'string') {
        v = v.trim()
      } else if (!v && v !== 0) {
        v = ''
      }

      if (!v && fnSeParametroVazio) {
        fnSeParametroVazio()
      }
      sqlFinal = substituirMetaParametros(k)

      if (['@dia', '@mes', '@ano'].includes(k)) {
        sqlFinal = sqlFinal.replaceAll(k, `${v}`)
      } else {
        sqlFinal = sqlFinal.replaceAll(k, `'${v}'`)
      }
    })
    return sqlFinal
  },
  procurarMetaParametro(sql, parametro) {
    let começar = 0
    let inicio = 0
    let final = -1

    const ocorrenciasParametro = new Set()

    while (inicio !== -1) {
      inicio = sql.indexOf('[', começar)
      if (inicio === -1) break

      final = sql.indexOf(']', começar) + 1
      const metaParametro = sql.substring(inicio, final)

      ocorrenciasParametro.add(metaParametro)
      começar = final
    }
    return ocorrenciasParametro
  }
}
