<template>
  <section :class="['informativos', posicaoNaTela]">
    <transition-group name="surgir-lista">
      <CardInformativo v-for="(card, i) in cards" :key="card.id" :posicao="i" />
    </transition-group>
  </section>
</template>

<script>
import CardInformativo from './CardInformativo.vue'

export default {
  components: {
    CardInformativo
  },
  computed: {
    cards() {
      return this.$store.state.cardsInformativos
    },
    posicaoNaTela() {
      if (this.$route.fullPath.includes('admin')) {
        return 'extrema-esquerda'
      } else {
        if (this.$store.state.sidebar.sidebarAberto) {
          return 'apos-sidebar-aberta'
        } else {
          return 'apos-sidebar-fechada'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.informativos {
  position: fixed;
  bottom: 0;

  width: 200px;
  height: fit-content;
  padding: 0.5rem;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

@media screen and (max-width: 500px) {
  .informativos {
    left: 0;
  }
}

@media screen and (min-width: 500px) {
  .extrema-direita {
    left: 0;
  }

  .apos-sidebar-aberta {
    left: 300px;
  }

  .apos-sidebar-fechada {
    left: 60px;
  }
}

.surgir-lista-enter-to {
  animation: entrar 1s ease-out forwards;
}

.surgir-lista-leave-active {
  animation: entrar 1s ease-out reverse;
}

@keyframes entrar {
  from {
    left: -350px;
  }
  to {
    left: 0;
  }
}
</style>
