<template>
  <router-view />

  <CardsInformativosConteiner />

  <ModalAlerta v-if="$store.state.modalAlertaAberto">
    <template #mensagem>{{ $store.state.mensagemAlerta }}</template>
  </ModalAlerta>

  <ModalSucesso v-if="$store.state.modalPositivoAberto">
    <template #mensagem>{{ $store.state.mensagemPositivo }}</template>
  </ModalSucesso>

  <ModalConfirmarAcao
    v-if="$store.state.confirmarAcao.aberto"
    @confirmar-acao="$store.state.confirmarAcao.fn"
    @fechar-modal="() => $store.dispatch('fecharConfirmarAcao')"
  >
    <template #mensagem>{{ $store.state.confirmarAcao.mensagem }}</template>
  </ModalConfirmarAcao>

  <Carregando v-if="$store.state.telaCarregandoAberta" />
</template>

<script>
import ModalAlerta from '@/components/modais/ModalAlerta'
import ModalSucesso from './components/modais/ModalSucesso.vue'
import ModalConfirmarAcao from './components/modais/ModalConfirmarAcao.vue'
import Carregando from '@/components/Carregando'
import CardsInformativosConteiner from './components/CardsInformativosConteiner.vue'

export default {
  components: {
    ModalAlerta,
    ModalSucesso,
    ModalConfirmarAcao,
    Carregando,
    CardsInformativosConteiner
  },
  beforeCreate() {
    this.$store.dispatch('definirSePlataformaEhMobile')
    this.$store.dispatch('iniciarCarregamento')
  },
  mounted() {
    this.$store.dispatch('pararCarregamento')
  }
}
</script>
