<template>
  <p class="modulos-descricao" v-if="queryModulos === 'acesso' && modulos.length">
    Todos os serviços oferecidos pela OG1 SYSTEMS
    <strong>nos quais você tem acesso</strong> se encontram aqui.
  </p>
  <p class="modulos-descricao" v-if="queryModulos === 'todos' && modulos.length">
    Todos os serviços oferecidos pela OG1 SYSTEMS se encontram aqui.
  </p>
  <p class="modulos-descricao" v-if="!modulos.length">
    Parece que nenhum serviço foi encontrado... Contate a OG1 para mais informações.
  </p>

  <main class="modulos-conteiner">
    <select class="query-modulos" style="max-width: 220px" v-model="queryModulos">
      <option value="acesso">Seus serviços</option>
      <option value="todos">Todos os serviços</option>
    </select>

    <div class="modulos">
      <div
        class="modulo-card"
        v-for="modulo in modulos"
        :key="modulo.id"
        :class="modulo.status !== 'A' ? 'inativo' : ''"
        @click.left="irAteModulo(modulo)"
      >
        <div class="modulo-card__titulo">
          <i class="material-icons-sharp">{{ modulo.icone }}</i>
          <h2>{{ modulo.nome }}</h2>
        </div>

        <p>{{ modulo.descricao }}</p>
      </div>
    </div>
  </main>
</template>

<script>
import req from '@/lib/utils/request'
import sessao from '@/lib/utils/sessao'

export default {
  data() {
    return {
      queryModulos: 'acesso',
      modulosRequest: []
    }
  },
  computed: {
    modulos() {
      return this.queryModulos === 'acesso'
        ? this.modulosRequest.filter((modulo) => modulo.status === 'A')
        : this.modulosRequest
    }
  },
  async mounted() {
    try {
      await this.getModulos()
    } catch (error) {
      this.$store.dispatch('abrirModalAlerta', req.tratarError(error))
    }
  },
  methods: {
    async getModulos() {
      const { data } = await this.$axios.get('/modulos/modulos')
      this.modulosRequest = data
    },
    irAteModulo(modulo) {
      if (modulo.status !== 'A') {
        this.$store.dispatch(
          'abrirModalAlerta',
          'Você ainda não tem acesso a esse módulo. Para mais informações contate o suporte da OG1.'
        )
        return
      }
      // if(modulo.moduloweb === 'tirapedido/rotas'){
      //     const pedido = sessao.getItemStorage('ITENS')

      //     if(pedido.length){
      //         this.$router.push({ name: 'homeTiraPedido' });
      //         return
      //     }
      // }
      this.$router.push(`/${modulo.moduloweb}`)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/vars';

.modulos-conteiner {
  overflow-y: auto;
  padding: 1rem;
  padding-bottom: 3.5rem;

  & .query-modulos {
    border-radius: 2px;
    border: none;
    border-bottom: 2px solid #cccccc;
  }
}

.modulos {
  margin-top: 1rem;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.modulo-card {
  cursor: pointer;
  box-sizing: border-box;
  width: 300px;
  min-height: 150px;
  box-shadow: 0px 5px 10px 0 #0000002c;

  padding: 1rem;
  border-radius: 4px;
  background-color: #ffffff;

  &__titulo {
    display: flex;
    align-items: center;
    margin-bottom: 0.6rem;

    & h2 {
      font-size: 1.5rem;
      font-weight: 500;
    }

    & i {
      padding: 5px;
      font-size: 1.5rem;
    }
  }

  & p {
    font-family: $poppins;
    color: #0a0a0acc;
  }

  &.inativo {
    opacity: 0.6;
    background-color: #f0f0f0;
  }
}

.modulos-descricao {
  padding: 0.5rem;
  margin: 0.5rem;
  margin-top: 0;
  border-radius: 2px;

  text-align: center;
  font-family: $poppins;
  background-color: #ff5e0096;

  & strong {
    font-weight: 500;
  }
}

@media screen and (min-width: 1024px) {
  .modulos {
    justify-content: flex-start;
  }
}
</style>
