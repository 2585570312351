<template>
  <div class="modal-conteiner" @click.stop>
    <div :class="['modal-base', noFixedWidth ? '' : 'fixed-width']">
      <slot></slot>
    </div>

    <div class="padding"></div>
  </div>
</template>

<script>
export default {
  props: {
    noFixedWidth: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/vars';
$border-radius: 4px;

.modal-conteiner {
  position: absolute;
  top: 1rem;

  min-width: 260px;
  height: fit-content;

  & .padding {
    width: 100%;
    height: 1rem;
  }
}

.modal-base {
  border-radius: $border-radius;
  background-color: $branco;
}

.fixed-width {
  width: 360px;
}
</style>
