import HomeTiraPedido from '@/views/tiraPedido/HomeTiraPedido.vue'

import app from '@/main'
import store from '@/store'
import {
  lazyLoad,
  verificarAcessoModulo,
  mudarModulo,
  tratarFaltaDeAcesso
} from '../lib/utils/router'
import sessao from '@/lib/utils/sessao'

export default [
  {
    path: '/tirapedido',
    name: 'tirapedido',
    component: HomeTiraPedido,
    redirect: {
      name: 'homeTiraPedido'
    },
    children: [
      {
        path: '',
        name: 'homeTiraPedido',
        component: lazyLoad('tiraPedido/Orcamento')
      },
      {
        path: 'consultar-clientes',
        name: 'consultarClientes',
        component: lazyLoad('tiraPedido/consultarClientes/ConsultarClientes')
      },
      {
        path: 'cadastrar-cliente',
        name: 'cadastrarCliente',
        component: lazyLoad('tiraPedido/consultarClientes/CadastrarCliente')
      },
      {
        path: 'consultar-materiais',
        name: 'consultarMateriais',
        component: lazyLoad('tiraPedido/ConsultarMateriais')
      },
      {
        path: 'consultar-orcamentos',
        name: 'consultarOrcamentos',
        component: lazyLoad('tiraPedido/ConsultarOrcamentos')
      },
      {
        path: 'incluir-item',
        name: 'incluirItem',
        component: lazyLoad('tiraPedido/IncluirItem')
      },
      {
        path: 'inserir-centro',
        name: 'inserirCentro',
        component: lazyLoad('tiraPedido/InserirCentro')
      },
      {
        path: 'inserir-nivel',
        name: 'inserirNivel',
        component: lazyLoad('tiraPedido/nivel/Nivel')
      },
      {
        path: 'rotas',
        name: 'rotas',
        component: lazyLoad('tiraPedido/rotas/Rotas')
      }
    ],
    beforeEnter: async (to) => {
      try {
        const { data } = await app.axiosInstance.get('/modulos/retornarUsuario')
        if (data) {
          store.dispatch('empresa/setEmpresa', data.USUARIO)
          store.dispatch('orcamento/salvarVendedor', data.VENDEDOR)
          store.dispatch('orcamento/salvarPDF', data.DADOSPDF)

          store.dispatch('orcamento/salvarPermissoes', data.PERMISSOES)
          store.dispatch('orcamento/salvarSaldoNegativo', data.SALDONEGATIVO)
          store.dispatch('orcamento/salvarLocalizacao', data.LOCALIZACAO)
          store.dispatch('orcamento/salvarCentroCustoAtivo', data.CENTRODECUSTO)
          store.dispatch('orcamento/salvarUsaTabelaPreco', data.USATABELAPRECO)

          const tabela = sessao.getItemSessao('TABELAPRECO')
          if (!tabela) {
            store.dispatch('orcamento/salvarTabelaPreco', data.TABELAPRECO)
          }

          ![null, undefined, ''].includes(data.IMAGENS)
            ? store.dispatch('orcamento/salvarImagem', 'T')
            : store.dispatch('orcamento/salvarImagem', 'F')

          const clienteRota = sessao.getItemStorage('CLIENTE')
          if (!clienteRota && !clienteRota.CODIGOROTA) {
            store.dispatch('orcamento/salvarCliente', data.PARCEIRO)
          }
        }
        const configuracoesIndividuais = await app.axiosInstance.get(
          '/modulos/retornarPermissoesUsuario'
        )
        const configuracoesGerais = await app.axiosInstance.get(
          '/modulos/retornarConfiguracoesGerais',
          { params: { CNPJ: store.state.empresa.CNPJ } }
        )
        const permissoes = {}
        Object.assign(permissoes, configuracoesGerais.data, configuracoesIndividuais.data)
        store.dispatch('empresa/setPermissoes', permissoes)

        await verificarAcessoModulo('tirapedido')
        await mudarModulo(to)
      } catch (error) {
        error = error.response.data.erro
        return tratarFaltaDeAcesso(to, error)
      }
    }
  }
]
