<template>
  <main class="pagina-login">
    <div class="pagina-login__principal">
      <section class="pagina-login__boas-vindas">
        <img
          class="pagina-login__boas-vindas-logo"
          src="@/assets/imgs/apple-icon.png"
          alt="Logo da OG1 Systems"
        />

        <div class="pagina_login__boas-vindas-texto">
          <p>Bem vindo ao OG1 WEB!</p>
          <p>Realize seu login.</p>
        </div>
      </section>

      <section class="pagina-login__login">
        <form action="#" class="pagina-login__login-form" v-if="$route.query.auth === 'empr'">
          <legend>Configuração de Conexão:</legend>

          <fieldset>
            <label>CNPJ</label>
            <input name="cnpj" v-model="cnpj" />
          </fieldset>

          <input
            type="submit"
            value="Conectar"
            id="validar-cnpj"
            @click.prevent="conectarServidor"
          />
        </form>

        <form
          action="#"
          autocomplete="off"
          class="pagina-login__login-form"
          v-if="$route.query.auth === 'user'"
        >
          <div class="form-floating">
            <label for="usuario-login">Usuário</label>
            <input
              id="usuario-login"
              type="text"
              name="usuario"
              autocomplete="username"
              v-model="usuario"
            />
          </div>
          <div>
            <label for="senha-login">Senha</label>
            <input
              type="password"
              name="senha"
              id="senha-login"
              autocomplete="current-password"
              maxlength="15"
              v-model="senha"
            />
          </div>

          <input id="submit" type="submit" value="Entrar" @click.prevent="efetuarLogin()" />

          <div class="linkRemover_div">
            <a class="linkRemover" @click.prevent="matarSessao()">Colocar CNPJ novamente</a>
          </div>
        </form>
      </section>
    </div>
  </main>

  <FooterOG1 class="login-footer" />
</template>

<script>
import FooterOG1 from '@/components/FooterOG1'

import req from '@/lib/utils/request'

export default {
  components: {
    FooterOG1
  },
  data() {
    return {
      cnpj: '',
      usuario: '',
      senha: ''
    }
  },
  methods: {
    async conectarServidor() {
      const store = this.$store
      store.dispatch('iniciarCarregamento')

      try {
        await this.$axios.post('/modulos/definirConfiguracoes', {
          CNPJ: this.cnpj
        })
        this.$router.push({ name: 'login', query: { auth: 'user' } })
      } catch (error) {
        store.dispatch('abrirModalAlerta', req.tratarError(error))
      } finally {
        store.dispatch('pararCarregamento')
      }
    },
    efetuarLogin() {
      const store = this.$store
      store.dispatch('iniciarCarregamento')

      this.$axios
        .post('/modulos/login', {
          LOGIN: this.usuario,
          SENHA: this.senha
        })
        .then((res) => {
          this.$router.push('/')
        })
        .catch((err) => {
          store.dispatch('pararCarregamento')
          store.dispatch('abrirModalAlerta', req.tratarError(err))
        })
    },
    matarSessao() {
      this.$axios
        .get('/modulos/matarSessao')
        .then((res) => {
          this.$router.push({ name: 'home' })
        })
        .catch((err) => {
          this.$store.dispatch('abrirModalAlerta', req.tratarError(err))
        })
    }
  },
  async beforeCreate() {
    try {
      await this.$axios.get(`/modulos/retornarUsuario`)
      this.$router.push({ name: 'home' })
    } catch (error) {
      error = error.response.data.erro

      if (error === 'USER_NON_AUTH')
        this.$router.replace({ name: 'login', query: { auth: 'user' } })
      else this.$router.replace({ name: 'login', query: { auth: 'empr' } })
    }
  }
}
</script>
