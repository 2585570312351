import sessao from '../lib/utils/sessao.js'

const store = {
  namespaced: true,
  state: {
    cadastros: [],
    nivel: '',
    niveis: [],
    registroNivel: ''
  },
  mutations: {
    salvarCadastros(state, cadastros) {
      state.cadastros = cadastros
    },
    salvarNivel(state, nivel) {
      state.nivel = nivel
    },
    setNiveis(state, nivel) {
      state.niveis = nivel
    },
    setRegistro(state, registro) {
      state.registroNivel = registro
    }
  },
  actions: {
    salvarCadastros({ commit }, cadastros) {
      sessao.addEmSessao('CADASTROS', cadastros)
      commit('salvarCadastros', cadastros)
    },

    salvarNivel({ commit }, nivel) {
      sessao.addEmSessao('NIVEL', nivel)
      commit('salvarNivel', nivel)
    },
    setNiveis({ commit }, nivel) {
      sessao.addEmSessao('NIVEIS', nivel)
      commit('setNiveis', nivel)
    },
    setRegistro({ commit }, registro) {
      sessao.addEmSessao('REGISTRO', registro)
      commit('setRegistro', registro)
    }
  },
  getters: {
    getItemById: (state) => {
      return state.items
    }
  }
}

export default {
  store
}
