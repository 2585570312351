import { createStore } from 'vuex'

import orcamento from './orcamento'
import cadastro from './cadastro'
import relatorios from './relatorios'
import empresa from './empresa'
import clienteVendas from './clienteVendas'

import geral from '../lib/utils/geral'
import sessao from '@/lib/utils/sessao'
import admin from './admin'
import sidebar from './sidebar'
import configuracoesWeb from './configuracoesWeb'
import dashboard from './dashboard'
import graficoDashboard from './graficoDashboard'
import desktopVendas from './desktopVendas'

const getDefaultState = () => {
  return {
    CARD_INFORMATIVO_ERRO: 'erro',
    CARD_INFORMATIVO_SUCESSO: 'sucesso',
    CARD_INFORMATIVO_CARREGANDO: 'carregando',
    modalAlertaAberto: false,
    modalPositivoAberto: false,
    mensagemAlerta: '',
    mensagemPositivo: '',
    telaCarregandoAberta: false,
    mensagemCarregando: '',
    sidebarAberto: false,
    ehMobile: false,
    carregandoInline: false,
    cardsInformativos: [],
    confirmarAcao: {
      aberto: false,
      mensagem: '',
      fn: () => {}
    }
  }
}

export default createStore({
  state: getDefaultState(),
  mutations: {
    resetarState(state) {
      Object.assign(state, getDefaultState())
      Object.assign(state.orcamento, orcamento.getDefaultState())
    },
    abrirModalAlerta(state) {
      state.modalAlertaAberto = true
    },
    mudarMensagemModalAlerta(state, mensagem) {
      state.mensagemAlerta = mensagem
    },
    fecharModalAlerta(state) {
      state.modalAlertaAberto = false
    },
    abrirModalPositivo(state) {
      state.modalPositivoAberto = true
    },
    mudarMensagemModalPositivo(state, mensagem) {
      state.mensagemPositivo = mensagem
    },
    fecharModalPositivo(state) {
      state.modalPositivoAberto = false
    },
    iniciarCarregamento(state) {
      state.telaCarregandoAberta = true
    },
    pararCarregamento(state) {
      state.telaCarregandoAberta = false
    },
    mudarMensagemCarregamento(state, mensagem) {
      if (mensagem) {
        state.mensagemCarregando = mensagem
      } else {
        state.mensagemCarregando = 'Aguarde, o OG1 Web está carregando...'
      }
    },
    toggleSidebar(state) {
      state.sidebarAberto = !state.sidebarAberto
    },
    fecharSidebar(state) {
      state.sidebarAberto = false
    },
    setEhMobile(state, boolean) {
      state.ehMobile = boolean
    },
    iniciarCarregamentoInline(state) {
      state.carregandoInline = true
    },
    pararCarregamentoInline(state) {
      state.carregandoInline = false
    },
    adicionarCardInformativo(state, { id, tipo, mensagem }) {
      state.cardsInformativos.push({ id, tipo, mensagem })
    },
    removerCardInformativo(state, id) {
      if (id) {
        const index = state.cardsInformativos.findIndex((c) => c.id === id)
        state.cardsInformativos.splice(index, 1)
      }
    },
    confirmarAcao(state, { mensagem, fn }) {
      state.confirmarAcao = {
        aberto: true,
        mensagem,
        fn
      }
    },
    fecharConfirmarAcao(state) {
      state.confirmarAcao = {
        aberto: false,
        mensagem: '',
        fn: () => {}
      }
    }
  },
  actions: {
    limparSessao({ commit }) {
      sessionStorage.clear()
      commit('resetarState')
    },
    abrirModalAlerta({ commit }, mensagem) {
      commit('mudarMensagemModalAlerta', mensagem)
      commit('abrirModalAlerta')
    },
    abrirModalPositivo({ commit }, mensagem) {
      commit('mudarMensagemModalPositivo', mensagem)
      commit('abrirModalPositivo')
    },
    fecharSidebarCasoMobile({ commit, state }) {
      if (state.ehMobile) {
        commit('fecharSidebar')
      }
    },
    definirSePlataformaEhMobile({ commit }) {
      const ehMobile = geral.verificarSeAPlataformaEhMobile()
      commit('setEhMobile', ehMobile)
    },
    resetarOrcamento({ commit }) {
      commit('resetarState')
      sessao.removerDoStorage('ITENS')
      sessao.removerDoStorage('CLIENTE')
      sessao.removerDoStorage('EDICAO')
    },
    iniciarCarregamento({ commit }, mensagem) {
      commit('mudarMensagemCarregamento', mensagem)
      commit('iniciarCarregamento')
    },
    pararCarregamento({ commit }) {
      commit('mudarMensagemCarregamento', '')
      commit('pararCarregamento')
    },
    iniciarCarregamentoInline({ commit }) {
      commit('iniciarCarregamentoInline')
    },
    pararCarregamentoInline({ commit }) {
      commit('pararCarregamentoInline')
    },
    mudarMensagemCarregamento({ commit }, mensagem) {
      commit('mudarMensagemCarregamento', mensagem)
    },
    adicionarCardInformativo({ commit }, { tipo, mensagem }) {
      const id = Date.now()
      commit('adicionarCardInformativo', { id, tipo, mensagem })
      return id
    },
    removerCardInformativo({ commit }, id) {
      commit('removerCardInformativo', id)
    },
    confirmarAcao({ commit }, { mensagem, fn }) {
      commit('confirmarAcao', { mensagem, fn })
    },
    fecharConfirmarAcao({ commit }) {
      commit('fecharConfirmarAcao')
    }
  },
  modules: {
    empresa: empresa.store,
    orcamento: orcamento.store,
    cadastro: cadastro.store,
    clienteVendas: clienteVendas.store,
    relatorios: relatorios.store,
    admin: admin.store,
    sidebar: sidebar.store,
    configuracoesWeb: configuracoesWeb.store,
    dashboard: dashboard.store,
    graficoDashboard: graficoDashboard.store,
    desktopVendas: desktopVendas.store
  }
})
