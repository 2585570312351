<template>
  <ModalInformativoTemplate>
    <template #header>Confirme sua ação.</template>

    <template #icon>priority_high</template>
    <template #message>
      <slot name="mensagem"></slot>
    </template>

    <template #footer>
      <BaseBotaoAcao @handleClick="$emit('confirmarAcao', this.item)">
        <slot name="confirmar"> Confirmar </slot>
      </BaseBotaoAcao>

      <BaseBotaoAcao @handleClick="$emit('fecharModal')">
        <slot name="desistir"> Desistir </slot>
      </BaseBotaoAcao>
    </template>
  </ModalInformativoTemplate>
</template>

<script>
import ModalInformativoTemplate from '../modais/templates/ModalInformativoTemplate.vue'
import BaseBotaoAcao from '../BaseBotaoAcao.vue'

export default {
  components: {
    ModalInformativoTemplate,
    BaseBotaoAcao
  },
  props: {
    item: Object
  }
}
</script>
