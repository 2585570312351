export class EstoqueExcedidoError extends Error {
  constructor(message) {
    super(message)
    this.name = 'EstoqueExcedidoError'
  }
}

export class MaterialDiferenteError extends Error {
  constructor(message) {
    super(message)
    this.name = 'MaterialDiferenteError'
  }
}
