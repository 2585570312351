<template>
  <div class="item-carrinho">
    <img :src="item.IMAGEM" alt="" class="item-carrinho__imagem" />

    <div class="item-carrinho__nome">
      <p>{{ item.DESCRICAO }}</p>
    </div>

    <div class="item-carrinho__quantidade">
      <BotaoAdicionarItem :item="item" />
    </div>

    <div
      v-if="promocao && ['F', null, undefined].includes(modoIndustria)"
      class="item-carrinho__promocao item-carrinho__preco"
    >
      <span>{{ preco }}</span>
      <span>{{ promocao }}</span>
    </div>

    <div
      v-if="!promocao && ['F', null, undefined].includes(modoIndustria)"
      class="item-carrinho__preco"
    >
      <span>{{ preco }}</span>
    </div>

    <div class="item-carrinho__lixeira" @click="excluirItemDoCarrinho">
      <span class="material-symbols-outlined">delete</span>
    </div>
  </div>
</template>

<script>
import BotaoAdicionarItem from './BotaoAdicionarItem.vue'
import fmt from '@/lib/utils/formatacao'
import sessao from '@/lib/utils/sessao'

export default {
  components: {
    BotaoAdicionarItem
  },
  props: ['codigoItem'],
  computed: {
    item() {
      return this.$store.state.clienteVendas.carrinho[this.codigoItem][0]
    },
    quantidade() {
      return this.$store.state.clienteVendas.carrinho[this.codigoItem].length
    },
    preco() {
      let preco = this.$store.getters['clienteVendas/getPreco'](this.codigoItem)
      preco = fmt.retornarNumberEmMonetario(preco)
      return `R$ ${preco}`
    },
    promocao() {
      let promocao = this.$store.getters['clienteVendas/getPromocao'](this.codigoItem)

      if (promocao === null) return promocao
      promocao = fmt.retornarNumberEmMonetario(promocao)
      return `R$ ${promocao}`
    },
    modoIndustria() {
      return sessao.getItemSessao('MODOINDUSTRIA')
    }
  },
  methods: {
    excluirItemDoCarrinho() {
      this.$store.dispatch('clienteVendas/excluirItemDoCarrinho', this.codigoItem)
    }
  }
}
</script>

<style lang="scss" scoped>
.item-carrinho {
  position: relative;

  box-sizing: border-box;
  width: 95vw;
  height: 60px;
  padding: 5px;
  margin: 0.5rem;
  box-shadow: 0px 1px 2px 2px #acacac3b;

  text-align: center;
  background-color: #ffff;

  display: flex;
  align-items: center;
  gap: 1rem;

  &__imagem {
    width: 50px;
    height: 50px;
  }

  &__nome {
    width: 100px;
    text-align: left;
    font-size: 0.8rem;
  }

  &__quantidade {
    position: relative;
    width: 100px;
    height: 30px;
  }

  &__preco {
    font-size: 0.9rem;
    text-align: right;
  }

  &__promocao {
    display: flex;
    flex-direction: column;

    & span:first-child {
      font-size: 0.7rem;
      text-decoration: line-through;
    }
  }

  &__lixeira {
    cursor: pointer;
    flex-grow: 1;
  }
}

@media screen and (min-width: 768px) {
  .item-carrinho {
    width: 98%;
  }
}

@media screen and (min-width: 1024px) {
  .item-carrinho {
    width: 100%;
  }
}
</style>
