const getDefaultState = () => {
  return {
    ordem: '',
    cliente: {
      codigo: '',
      descricao: ''
    },
    vendedor: {
      codigo: '',
      descricao: ''
    },
    pagamento: {
      forma: {
        codigo: '',
        descricao: '',
        alteraParcela: ''
      },
      tipos: []
    },
    itens: [],
    observacao: '',
    valorTotal: 0,
    descontoTotal: 0,
    tabelaPreco: {
      codigotabelapreco: '',
      descricaotabelapreco: ''
    },
    centroCusto: '',
    status: ''
  }
}

const store = {
  namespaced: true,
  state: {
    fechamento: getDefaultState()
  },
  mutations: {
    definirOrdem(state, ordem) {
      state.fechamento.ordem = ordem
    },
    definirCliente(state, { codigo, descricao }) {
      state.fechamento.cliente.codigo = codigo
      state.fechamento.cliente.descricao = descricao
    },
    definirVendedor(state, { codigo, descricao }) {
      state.fechamento.vendedor.codigo = codigo
      state.fechamento.vendedor.descricao = descricao
    },
    definirObservacao(state, observacao) {
      state.fechamento.observacao = observacao
    },
    definirFormaPagamento(state, { codigo, descricao, alteraParcela }) {
      state.fechamento.pagamento.forma.codigo = codigo
      state.fechamento.pagamento.forma.descricao = descricao

      if (typeof alteraParcela === 'string') {
        alteraParcela = alteraParcela === 'T'
      }
      state.fechamento.pagamento.forma.alteraParcela = alteraParcela
    },
    definirTiposPagamento(state, tipos) {
      state.fechamento.pagamento.tipos = tipos
    },
    definirItens(state, itens) {
      state.fechamento.itens = itens
    },
    definirValorTotal(state, valorTotal) {
      state.fechamento.valorTotal = valorTotal
    },
    definirDescontoTotal(state, descontoTotal) {
      state.fechamento.descontoTotal = descontoTotal
    },
    definirTabelaPreco(state, tabelaPreco) {
      state.fechamento.tabelaPreco = tabelaPreco
    },
    definirCentroCusto(state, centroCusto) {
      state.fechamento.centroCusto = centroCusto
    },
    definirStatus(state, status) {
      state.fechamento.status = status
    },
    resetFechamento(state) {
      Object.assign(state.fechamento, getDefaultState())
    }
  },
  actions: {
    definirOrdem({ commit }, ordem) {
      commit('definirOrdem', ordem)
    },
    definirCliente({ commit }, { codigo, descricao }) {
      commit('definirCliente', { codigo, descricao })
    },
    definirVendedor({ commit }, { codigo, descricao }) {
      commit('definirVendedor', { codigo, descricao })
    },
    definirObservacao({ commit }, observacao) {
      commit('definirObservacao', observacao)
    },
    definirFormaPagamento({ commit }, { codigo, descricao, alteraParcela }) {
      commit('definirFormaPagamento', { codigo, descricao, alteraParcela })
    },
    definirTiposPagamento({ commit }, tipos) {
      commit('definirTiposPagamento', tipos)
    },
    definirItens({ commit }, itens) {
      commit('definirItens', itens)
    },
    definirValorTotal({ commit }, valorTotal) {
      commit('definirValorTotal', valorTotal)
    },
    definirDescontoTotal({ commit }, descontoTotal) {
      commit('definirDescontoTotal', descontoTotal)
    },
    definirTabelaPreco({ commit }, tabelaPreco) {
      commit('definirTabelaPreco', tabelaPreco)
    },
    definirCentroCusto({ commit }, centroCusto) {
      commit('definirCentroCusto', centroCusto)
    },
    definirStatus({ commit }, status) {
      commit('definirStatus', status)
    },
    definirResetFechamento({ commit }) {
      commit('resetFechamento')
    }
  }
}
export default { store }
