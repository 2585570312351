import router from '@/router'
import { AxiosError } from 'axios'

import dotenv from 'dotenv'
dotenv.config()

/**
 * Filtra a mensagem de erro independente da estrutura do objeto.
 * Auxilia em try-catch's que podem lançar tanto erro de execução quanto `AxiosError`.
 *
 * @param {(Error | AxiosError)} error - erro lançado pela aplicação
 * @returns {String} mensagem de erro no objeto
 */
function tratarError(error) {
  if (error.response) {
    const status = error.response.status

    if (status === 401) {
      router.push('/login')
      return 'Sua sessão expirou! Entre novamente'
    } else if (error.code && error.code === process.env.VUE_APP_ERR_NETWORK) {
      return 'Erro de conexão. Não foi possível fazer o login'
    }
    return error.response.data.erro
  } else {
    return error.message
  }
}

export default { tratarError }
