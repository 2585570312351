<template>
  <div :class="$store.state.clienteVendas.headerFixa ? 'header-fixa' : 'header-estatica'">
    <header class="header-vendas">
      <RouterLink to="/cliente-vendas/produtos">
        <img class="logo" src="@/assets/imgs/apple-icon-branco.png" alt="Logo-Og1" />
      </RouterLink>

      <section class="vendas-pesquisar" v-if="barraPesquisa">
        <input
          class="vendas-pesquisar__input"
          type="search"
          placeholder="pesquisar..."
          v-model="pesquisa"
        />

        <span @click="pesquisar()" class="vendas-pesquisar__botao"> Buscar </span>
      </section>

      <div class="vendas-carrinho">
        <section class="vendas-pesquisar-mobile">
          <i class="material-symbols-outlined pesquisar-mobile" @click="togglePesquisa">search</i>

          <article v-if="pesquisaAberta">
            <input type="search" placeholder="Pesquisar..." v-model="pesquisa" />
            <button class="vendas-pesquisar__botao">Buscar</button>
          </article>
        </section>

        <span @click="abrirCarrinho()" class="material-symbols-outlined carrinho">
          shopping_cart
          <p class="quantidade-itens-carrinho">
            {{ $store.getters['clienteVendas/getQuantidadeCarrinho'] }}
          </p>
        </span>

        <ModalPerfil :vendas="true" />
      </div>
    </header>
  </div>
</template>

<script>
import ModalPerfil from '../ModalPerfil.vue'

export default {
  components: {
    ModalPerfil
  },
  props: ['barraPesquisa'],
  data() {
    return {
      pesquisaAberta: false,
      pesquisa: ''
    }
  },
  methods: {
    abrirCarrinho() {
      this.$store.commit('clienteVendas/abrirCarrinho')
    },
    togglePesquisa() {
      this.pesquisaAberta = !this.pesquisaAberta
    },
    async pesquisar() {
      if (this.pesquisa) {
        this.$router.replace({ name: 'pesquisaProdutos', params: { pesquisa: this.pesquisa } })
        await this.$store.dispatch('clienteVendas/pesquisar', this.pesquisa)
      } else {
        this.$router.push('/cliente-vendas/produtos')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header-fixa {
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
}

.header-estatica {
  position: static;
}

.header-vendas {
  box-sizing: border-box;
  position: relative;

  height: 80px;
  padding: 0.5rem;
  background-color: #212121;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  & .logo {
    width: 5.2rem;
  }
}
.vendas-pesquisar {
  width: 50%;
  padding: 2% 0;
  border: none;
  outline: none;

  display: none;
  align-items: center;
  justify-content: center;

  &__input {
    box-sizing: border-box;

    width: 100%;
    height: 34px;
    padding: 4px;
    border: none;
    border-radius: 0%;
    border-radius: 2px;
    transition: all 0.4s ease-in-out;
  }

  &__botao {
    box-sizing: border-box;
    cursor: pointer;

    width: 100px;
    height: 34px;
    border: none;

    font-size: 1rem;
    color: #fff;
    font-weight: 500;
    background-color: #212121;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      transition: 150ms ease-in;
      opacity: 0.8;
    }
  }

  &-mobile {
    & .pesquisar-mobile {
      font-size: 2.2rem;
      color: white;
    }

    & article {
      box-sizing: border-box;
      position: absolute;
      left: 0;
      bottom: -45px;

      width: 100%;
      height: 50px;
      padding: 0.5rem;
      background-color: #212121;

      display: flex;
      align-items: center;

      & input {
        height: 34px;
        width: 100%;
        border: none;
        border-radius: 2px;
      }
    }
  }
}

.vendas-carrinho {
  display: flex;
  align-items: center;

  & .carrinho {
    user-select: none;

    position: relative;
    width: 34px;
    height: 34px;
    margin-left: 0.5rem;
    margin-right: 1rem;
    font-size: 2.2rem;
    color: white;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: ease-in-out 0.2s;

    & .quantidade-itens-carrinho {
      box-sizing: border-box;

      position: absolute;
      top: -0.6rem;
      right: -0.6rem;

      width: 25px;
      height: 25px;
      padding: 5px;
      border-radius: 50%;

      font-family: 'Montserrat', sans-serif;
      font-size: 1rem;
      background-color: #ff4500;
      color: black;
      font-weight: 500;

      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:hover {
      color: #ff4500;
      & .quantidade-itens-carrinho {
        color: black;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .vendas-pesquisar {
    &-mobile {
      display: none;
    }

    display: flex;
  }
}
</style>
