<template>
  <div>
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
div {
  margin: 0.5rem;
}

@media screen and (min-width: 900px) {
  div {
    width: 80%;
    margin: 0.5rem auto;
  }
}
</style>
