<template>
  <header class="header">
    <div class="header__titulo">
      <img src="@/assets/imgs/apple-icon.png" alt="Logo da OG1 SYSTEMS" class="logo" />
      <h1>{{ pagina }}</h1>
    </div>

    <i class="material-icons-sharp menu" @click.left="$store.dispatch('sidebar/abrirSidebar')">
      menu
    </i>

    <div class="modulos-botao-logout" @click="abrirModalConfirmar">
      <i class="material-icons-sharp">logout</i>
      <span>Sair</span>
    </div>
    <ModalConfirmarAcao
      v-if="modalConfirmarAberto"
      @confirmarAcao="deslogar"
      @fecharModal="fecharModalConfirmar"
    >
      <template #mensagem>Tem certeza que deseja deslogar do sistema?</template>
    </ModalConfirmarAcao>
  </header>
</template>

<script>
import ModalPerfil from './ModalPerfil.vue'
import sessao from '../lib/utils/sessao'
const nomeEmpresa = sessao.getItemSessao('PDF')
import ModalConfirmarAcao from './modais/ModalConfirmarAcao.vue'

export default {
  components: {
    ModalPerfil,
    ModalConfirmarAcao
  },
  data() {
    return {
      nomeFantasia: '',
      logoRota: '',
      nav: [],
      modalConfirmarAberto: false
    }
  },
  computed: {
    pagina() {
      const path = this.$route.path
      if (path.includes('modulos')) return 'Serviços'
      else if (path.includes('configuracoes-web')) return 'Configurações'
      else if (path.includes('tirapedido')) return 'Tira Pedido'
      else if (path.includes('dashboard')) return 'Dashboard'
      else if (path.includes('relatorios')) return 'Relatórios'
      else if (path.includes('desktop-vendas')) return 'Vendas'
      else return ''
    }
  },
  beforeMount() {
    const fullPath = this.$route.fullPath
    if (fullPath.includes('tirapedido')) {
      this.nav = [
        {
          rota: '/tirapedido/consultar-clientes',
          icon: 'person_search',
          descricao: 'Consultar Clientes'
        },
        {
          rota: '/tirapedido/consultar-materiais',
          icon: 'inventory_2',
          descricao: 'Consultar Materiais'
        },
        {
          rota: '/tirapedido/consultar-orcamentos',
          icon: 'content_paste_search',
          descricao: 'Consultar Orçamentos'
        }
      ]
      this.logoRota = '/tirapedido'
    } else if (fullPath.includes('relatorios')) {
      this.logoRota = '/relatorios'
    } else if (fullPath.includes('pre-venda')) {
      this.logoRota = '/pre-venda'
    }

    if ([null, undefined, ''].includes(nomeEmpresa)) {
      this.nomeFantasia = 'OG1 ERP'
    } else if ([null, undefined, ''].includes(nomeEmpresa.NOMEFANTASIA)) {
      this.nomeFantasia = 'OG1 ERP'
    } else {
      this.nomeFantasia = nomeEmpresa.NOMEFANTASIA.substring(0, 12)
    }
  },
  methods: {
    abrirModalConfirmar() {
      this.modalConfirmarAberto = true
    },
    fecharModalConfirmar() {
      this.modalConfirmarAberto = false
    },
    deslogar() {
      this.$axios.get('/modulos/logout').then(() => {
        this.$store.dispatch('resetarOrcamento')
        this.$store.dispatch('limparSessao')
        this.$router.push('/login')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  position: relative;

  box-sizing: border-box;
  height: 80px;
  padding: 1rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  & .menu {
    font-size: 1.8rem;
  }

  &__titulo {
    display: flex;
    align-items: center;
    gap: 0.7rem;

    & .logo {
      user-select: none;
      width: 4rem;
    }

    & h1 {
      font-size: 1.4rem;
      text-transform: uppercase;
      color: #0a0a0aa4;
      letter-spacing: 0.1rem;
    }
  }

  & .modulos-botao-logout {
    cursor: pointer;

    position: absolute;
    right: 1rem;

    border-radius: 2px;
    padding: 0.5rem 0.8rem;
    box-shadow: 0px 3px 5px 0 #0000002c;
    background-color: #fff;

    display: none;
    align-items: center;
    gap: 0.3rem;

    & span {
      text-transform: uppercase;
    }
  }
}

@media screen and (min-width: 700px) {
  .header {
    & .menu {
      display: none;
    }

    & .modulos-botao-logout {
      display: flex;
    }
  }
}
</style>
